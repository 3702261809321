module.exports = {
  // TODO: Figure out which fields are actually needed here then remove the rest from all properties
  gatsbyConfig: {
    property: 'woodwardparkcity',
    siteName: 'Woodward Park City',
    siteUrl: 'https://www.woodwardparkcity.com/',
    reCaptchaDBPropertyName: 'Woodwardparkcity',
    reCaptchaPublicKey: '6LfiiYIUAAAAAEvSxGg7A_ZOQRemWMgGBoTr0Z5P',
    color: '#64CAC7',
    facebook: 'borealmtn',
    instagram: 'borealmtn',
    twitter: 'borealmtn',
    dynamicTypes: [
      'pressRelease',
      'event',
      'blog',
    ],
  },
  // TODO: Good way of managing API keys per site, maybe use for formbucket and mailchimp?
  contentfulConfig: {
    spaceId: '38vc2unmqsq5',
    accessToken: 'D2b0ZG2uKbPvWdW38GS0y3_hQN9AxH-iJnKijjoI0cA',
  },
};
